import * as React from 'react';
import { FaFacebookSquare } from 'react-icons/fa';
import './Footer.css';


function Footer(){
    return(
        <React.Fragment>
            <div className='contentF'>
                <h2 className='title'>Síguenos</h2>
                <div className='iconsF'>
                    <a href='https://www.facebook.com/itfortal' target="_blank" rel="noopener noreferrer"><FaFacebookSquare className="social-icon" /></a>
                    {/* <a href='http:/hola'><FaTwitter className="social-icon" /></a>
                    <a href='http:/hola'><FaWhatsapp className="social-icon" /></a>
                    <a href='http:/hola'><FaInstagram className="social-icon" /></a> */}
                </div>
                <p className='copyr'>© Copyright {new Date().getFullYear()} ITFortal</p>
            </div>
        </React.Fragment>
    )
}

export default Footer;