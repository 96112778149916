import React, { Component } from 'react';
import NavBar  from './NavBar';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <React.Fragment>
        <NavBar />
        <div className="bodyContainer">
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}
