import * as React from 'react'
import './Nosotros.css'

function Nosotros(){
    return(
        <React.Fragment>
            <div id='nosotros' className='contentN'>
                <h1 className='title'>¿Quiénes somos?</h1>
                <div className='textN'>
                    <p>
                        <b>IT Fortal</b> es una empresa mexicana proveedora de <b>soluciones</b> y <b>servicios</b> tecnológicos e informáticos altamente escalables e intuitivas que se
                        alinean a los procesos principales de las empresas generando un gran
                        impacto de valor en ellas.
                    </p>
                    <p>
                        Gracias a nuestro fuerte <b>compromiso</b> y <b>pasión</b>, desarrollamos
                        <b> aplicaciones web</b>, <b>móviles</b> y de <b>escritorio</b>  utilizando 
                        tecnologías de vanguardia.
                    </p>
                    <br/>

                    <div className='cardN cardL'>
                        <div className='cardNT cardNT-L'>
                            <h2>Misión</h2>
                        </div>
                        <div className='cardNTDR'>
                            <p>
                            Ayudar a nuestros clientes creando herramientas tecnológicas que 
                            contribuyan en la resolución de sus problemáticas, automatización de
                            procesos y eficientar recursos.
                            </p>
                        </div>
                    </div>
                    <div className='cardN cardR'>
                        <div className='cardNT cardNT-R'>
                            <h2>Visión</h2>
                        </div>
                        <div className='cardNTDL'>
                            <p>
                            Ser la empresa de desarrollo de software más reconocida, 
                            otorgando herramientas integrales y de alta calidad e innovación.
                            </p>
                        </div>
                        
                    </div>
                    <div className='cardN cardL'>
                        <div className='cardNT cardNT-L'>
                            <h2>Valores</h2>
                        </div>
                        <div className='cardNTDR'>
                            <p>
                            Innovación,
                            calidad,
                            responsabilidad,
                            pasión y
                            trabajo en equipo.
                            </p>
                        </div>
                    </div>

                </div>
                
                
            </div>
            
        </React.Fragment>
    );
}

export default Nosotros;