import React, { useState } from 'react';
import { Drawer } from 'antd';
import {Nav, NavLink, Bars, NavMenu, NavIcon, NavLink2, DivContent, PContent, ButtonBars} from './NavBarElements'

const NavBar = () => {
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return(
        <header>
            <Nav>
                <NavLink to='/'>
                    <NavIcon src='img/ITFORTAL_T_icon1.png' alt='Logo'/>
                </NavLink>
                <ButtonBars  onClick={showDrawer}>
                    <Bars />
                </ButtonBars >
                <Drawer
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                >
                    <DivContent>
                        <PContent>
                            <a href='#nosotros' onClick={onClose}>Nosotros</a>
                        </PContent>
                        {/* <PContent>
                            <a href='#productos' onClick={onClose}>Productos</a>
                        </PContent>
                        <PContent>
                            <a href='#servicios' onClick={onClose}>Servicios</a>
                        </PContent> */}
                        <PContent>
                            <a href='#clientes' onClick={onClose}>Experiencia</a>
                        </PContent>
                        <PContent>
                            <a href='#contacto' onClick={onClose}>Contacto</a>
                        </PContent>
                    </DivContent>
                    
                </Drawer>
                <NavMenu>
                    <NavLink2 href='#nosotros'>
                        Nosotros
                    </NavLink2>
                    {/* <NavLink2 href='#productos'>
                        Productos
                    </NavLink2>
                    <NavLink2 href='#servicios'>
                        Servicios
                    </NavLink2> */}
                    <NavLink2 href='#clientes'>
                        Experiencia
                    </NavLink2>
                    <NavLink2 href='#contacto'>
                        Contacto
                    </NavLink2>
                </NavMenu>
                
            </Nav>
        </header>
    );
};

export default NavBar;