import React from 'react'
import { Carousel} from 'antd';

import './Slider.css';

const Slider = () =>{
    return(
        <div >
            <Carousel effect="fade" autoplay>
                <div className='contentStyle'>
                    <img className='img-Slider' src='img/slider-1.jpg' alt='initial'/>
                    <div className="top-left">Top Left</div>
                </div>
                
                <div className='contentStyle'>
                    <img className='img-Slider' src='img/work_c.jpg' alt='work b'/>
                </div>
                <div className='contentStyle'>
                    <img className='img-Slider' src='img/industry_c.jpg' alt='industry'/>
                </div>
                <div className='contentStyle'>
                    <img className='img-Slider' src='img/analytics_a.jpg' alt='analytics'/>
                </div>
            </Carousel>
        </div>
        
    );

}

export default Slider;