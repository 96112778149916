import * as React from 'react';
import { Form, Input, Button, notification } from 'antd';
import './Contacto.css';
import { sendEmail } from "../services/emailService"; 


  const validateMessages = {
    required: 'El campo obligatorio!',
    types: {
      email: 'El formato no es valido!',
    }
  };

function Contacto(){
    const formRef = React.createRef();

    const Notification = (type, title, message) => {
        notification[type]({
          message: title,
          description: message,
        });
      };

    const onFinish = (values) => {
        console.log(values);
        const ContactEmailRequest = {
            Email : values.user.email,
            Name : values.user.name,
            Message : values.user.mensaje
        }
        async function email(){
            try {
                await sendEmail(ContactEmailRequest);
                 Notification('success', 'Se envió el email.', '');
                 onReset();
            } catch (error) {
                console.log(error)
                 Notification('error', 'Error al enviar el email.', '');
            } finally {}
        }
        email();
    };

    const onReset = () => {
        formRef.current.resetFields();
      };

    return(
        <React.Fragment>
            <div id='contacto' className='contentContact'>
                <h1 className='title'>Contáctanos</h1>
                <div className='form'>
                    <Form ref={formRef} layout='vertical' name="nest-messages" onFinish={onFinish} 
                    validateMessages={validateMessages}
                    method='post'
                    >
                        <Form.Item
                            name={['user', 'name']}
                            label="Nombre completo:"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['user', 'email']}
                            label="Correo electrónico:"
                            rules={[
                            {
                                required: true,
                                type: 'email',
                                
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name={['user', 'mensaje']} label="Mensaje:">
                            <Input.TextArea autoSize={{minRows:6}}/>
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                Enviar
                            </Button>
                        </Form.Item>
                    </Form>

                </div>
            </div>

        </React.Fragment>
    )
}

export default Contacto;