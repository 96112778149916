import * as React from 'react';
import './Clientes.css'

function Clientes(){
    return(
        <React.Fragment>
            <div id='clientes' className='contentC'>
                <h1 className='title'>Nuestros Clientes</h1>
                <div className='logosC'>
                    <img src='img/mptyt.png' alt='Mi pueblo tacos y tequila'/>
                </div>
                
            </div>
        </React.Fragment>

    )
}

export default Clientes;