import React, { Component } from 'react';
import Slider from './Slider';
import Nosotros from './Nosotros';
import Clientes from './Clientes';
import Footer from './Footer';
import Contacto from './Contacto'

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <Slider/>
        <Nosotros/>
        <Clientes/>
        <Contacto/>
        <Footer/>
      </div>
    );
  }
}
