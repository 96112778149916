import styled from 'styled-components'
import {NavLink as Link} from 'react-router-dom'
import {FaBars} from 'react-icons/fa'

export const Nav = styled.nav`
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    height: 77px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem calc((100vw - 1000px) / 2);
    z-index:10;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
`

export const NavIcon = styled.img`
    width: 100px;
    background-color: white;
    border-radius: 10px;
    margin-top: 2.7rem;
    box-shadow: -7px -7px 15px rgb(0 0 0 / 10%), 7px 7px 15px rgb(0 0 0 / 10%);

    @media screen and (max-width: 768px){
        width: 90px;
    }
`

export const NavLink = styled(Link)`
    color: #256ce1;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active{
        color: #fff;
    }
`

export const NavLink2 = styled.a`
    color: #256ce1;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 125%;
    cursor: pointer;
    font-variant: petite-caps;
    font-size: 20px;

    &:hover {
        color: #256ce1;
        border-bottom: 4px solid #256ce1;
        transition: all 0.2s ease-out;
      }
`

export const DivContent = styled.div`
    text-align: center;
    font-size: 18px;
`

export const PContent = styled.p`
      margin: 3rem;
`

export const Bars = styled(FaBars)`
    display: none;
    color: #256ce1;

    @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
    }
`

export const ButtonBars = styled.button`
    display: none;

    @media screen and (max-width: 768px) {
    display: block;
    }
`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px){
        display: none
    }
`

export const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: #256ce1;
    padding: 10px 22px;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`